import React from 'react';
import PropTypes from 'prop-types';
import Constants from '../../config/const';
import Image from './image';
import classes from './classes';

const { width, height } = Constants.silhouette;

const Silhouette = ({
  dpi, x, y, silhouette
}) => {
  const style = {
    width: width * dpi,
    height: height * dpi,
    left: x,
    top: y
  };
  return (
    <div style={ style } className={ classes.silhouette }>
      <Image
        widthIn={ width }
        heightIn={ height }
        dpi={ dpi }
        src={ Constants.silhouette.mapping[silhouette] }
      />
    </div>
  );
};

Silhouette.propTypes = {
  dpi: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  silhouette: PropTypes.string.isRequired
};

export default Silhouette;
