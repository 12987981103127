import Constants from 'arts/config/constants';
import {
  getCartItems, deleteCartItem, addToCartApi, addToCartGenericApi
} from 'arts/config/api';
import { onAddedToCart, onRemovedFromCart, trackEvent } from '../config/analytics';

const dispatchUpdateCartItems = (apiFunc, params, dispatch) => {
  dispatch({ type: Constants.CartItems.ADDING });
  apiFunc(params).then(
    ({
      cartItems, summaryOfCharges, savedItem, locale, analyticsEventId, pendingCartItemsCount
    }) => {
      dispatch({ type: Constants.CartItems.ADDED });
      dispatch({
        type: Constants.CartItems.FETCH_SUCCESS,
        collection: cartItems,
        summaryOfCharges,
        savedItem,
        locale,
        pendingCartItemsCount
      });
      setTimeout(() => {
        dispatch({ type: Constants.CartItems.RESET_ADDED });
      }, 1000);
      if (savedItem) {
        const items = savedItem.id ? [savedItem] : (savedItem.items || []);
        onAddedToCart(items, analyticsEventId);
      }
    },
    (error) => dispatch({
      type: Constants.CartItems.FETCH_FAILURE,
      error
    })
  );
};

export const fetchCartItems = (orderId) => (dispatch) => dispatchUpdateCartItems(
  getCartItems, orderId, dispatch
);

export const resetSavedItem = () => dispatch => dispatch({
  type: Constants.CartItems.RESET_SAVED
});

const processAddToCart = (opts, kind) => dispatch => {
  const {
    frameId, paperId, workId, originalArtId, productId, giftPrice, nonGlare
  } = opts;
  let mpProps = {};
  if (kind === 'art') {
    mpProps = {
      'frame id': frameId,
      'paper id': paperId,
      'work id': workId,
      'non glare': nonGlare ? 'yes' : 'no'
    };
  } else if (kind === 'original') {
    mpProps = {
      'original art id': originalArtId
    };
  } else if (kind === 'giftcard') {
    mpProps = {
      'item price': giftPrice
    };
  } else if (kind === 'product') {
    mpProps = {
      'product id': productId
    };
  }
  trackEvent(`add to cart - ${ kind }`, mpProps);
  dispatchUpdateCartItems(
    addToCartApi, { ...opts, kind }, dispatch
  );
};

// frameId, paperId, workId
export const addArtToCart = (opts) => processAddToCart(opts, 'art');

// originalArtId
export const addOriginalArtToCart = (opts) => processAddToCart(opts, 'original');

// productId
export const addProductToCart = (opts) => processAddToCart(opts, 'product');

// giftPrice, giftRecipientEmail, giftRecipientName, giftSenderName, giftDeliveryDate, giftMessage
export const addGiftCardToCart = (opts) => processAddToCart(opts, 'giftcard');

export const addWallToCart = (wallBuilderWallId, overrides) => dispatch => {
  trackEvent('add to cart - wall', { 'wall id': wallBuilderWallId, 'has overrides': (Object.keys(overrides || {}).length > 0) });
  dispatchUpdateCartItems(
    addToCartGenericApi, { wallBuilderWallId, overrides }, dispatch
  );
};

export const addWallPicturesToCart = (wallPictureIds, sourceWallId, overrides) => dispatch => {
  trackEvent('add to cart - wall pictures', { 'wall id': sourceWallId, 'picture ids': wallPictureIds, 'has overrides': (Object.keys(overrides || {}).length > 0) });
  dispatchUpdateCartItems(
    addToCartGenericApi, { wallPictureIds, sourceWallId, overrides }, dispatch
  );
};

export const addShoppableLookToCart = (shoppableLookId) => (dispatch) => {
  trackEvent('add to cart - inspiration', { 'look id': shoppableLookId });
  dispatchUpdateCartItems(
    addToCartGenericApi, { shoppableLookId }, dispatch
  );
};

export const removeCartItem = (id) => (dispatch) => {
  deleteCartItem(id)
    .then(
      ({
        cartItems, summaryOfCharges, savedItem, locale, pendingCartItemsCount
      }) => {
        dispatch({
          type: Constants.CartItems.FETCH_SUCCESS,
          collection: cartItems,
          summaryOfCharges,
          locale,
          pendingCartItemsCount
        });
        onRemovedFromCart(savedItem);
      }
    ).catch(() => {});
};
