/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Modal from 'arts/components/Modal';
import { Loader } from 'semantic-ui-react';
import {
  Formik, Form, Field
} from 'formik';
import { MODAL_LEVEL2_SELECTOR } from 'arts/config/constants';
import { acceptCookiesGTM } from 'arts/config/analytics';
import Classes from './styles';
import { request } from '../request';

const ALL_OFF = {
  analyt: false,
  func: false,
  adv: false
};

// eslint-disable-next-line no-unused-vars
const allowAll = () => {
  acceptCookiesGTM(Object.keys(ALL_OFF));
  request(
    `/${ window.currentLocale.currentLocale }/cookies_consents`, {
      method: 'POST',
      body: JSON.stringify({ allow_all: true })
    }
  ).then(() => {
    window.location.reload();
  });
};

const allowPartial = (values) => {
  const accepted = [];
  Object.keys(values).forEach(k => {
    if (values[k]) {
      accepted.push(k);
    }
  });
  acceptCookiesGTM(accepted);
  request(
    `/${ window.currentLocale.currentLocale }/cookies_consents`, {
      method: 'POST',
      body: JSON.stringify(values)
    }
  ).then(() => {
    window.location.reload();
  });
};

const CookiesConsent = () => {
  const [details, setDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const onAllowAll = () => {
    setLoading(true);
    allowAll();
  };

  const onAllowPartial = (values, { setSubmitting }) => {
    setSubmitting(true);
    setLoading(true);
    allowPartial(values);
  };

  const showDetails = () => setDetails(true);

  if (!window.consentPopup) { return null; }

  const manage = details ? null : (
    <button type="button" className={ Classes.manage } onClick={ showDetails }>
      Manage Cookies
    </button>
  );

  const actions = loading ? (
    <Loader active inline />
  ) : (
    <>
      <button type="button" className={ Classes.allowAll } onClick={ onAllowAll }>
        Allow All
      </button>
      { manage }
    </>
  );

  const submit = loading ? null : (
    <div className={ Classes.actions }>
      <button type="submit" className={ Classes.manage }>
        Save Preferences
      </button>
    </div>
  );

  const renderDetails = details ? (
    <div className={ Classes.details }>
      <Formik
        initialValues={ ALL_OFF }
        onSubmit={ onAllowPartial }
      >
        {
          ({
            isSubmitting
          }) => (
            <Form>
              <div>
                <label htmlFor="essential">
                  <input type="checkbox" checked disabled id="essential" />
                  <span>Essential</span>
                </label>
                These cookies are needed for essential functions such as making payments
                and placing orders. Essential cookies cannot be switched off and they
                don`t store any of your information.
              </div>
              <div>
                <label htmlFor="func">
                  <Field
                    id="func"
                    type="checkbox"
                    name="func"
                    disabled={ isSubmitting }
                  />
                  <span>Functional</span>
                </label>
                In an effort to try to provide you with the best site functionality,
                these cookies help us and our third party service providers diagnose
                server and software errors, as well as track and mitigate any improper use.
              </div>
              <div>
                <label htmlFor="analyt">
                  <Field
                    id="analyt"
                    type="checkbox"
                    name="analyt"
                    disabled={ isSubmitting }
                  />
                  <span>Analytics</span>
                </label>
                So that we and our service providers, including Google and New Relic,
                can continue optimizing the site and adding new features for users to enjoy,
                these cookies allow us to understand how users move around our site and
                interact with different functionalities.
              </div>
              <div>
                <label htmlFor="adv">
                  <Field
                    id="adv"
                    type="checkbox"
                    name="adv"
                    disabled={ isSubmitting }
                  />
                  <span>Targeting or Advertising Cookies</span>
                </label>
                So that we can provide users with promotions that are most relevant to them,
                our advertising providers, including Google and Facebook, use cookies and web
                pixels to identify and store behaviors that users take when visiting our site
                (such as pages visited and search terms used).
              </div>
              { submit }
            </Form>
          )
        }
      </Formik>
    </div>
  ) : null;

  return (
    <Modal
      selector={ MODAL_LEVEL2_SELECTOR }
      headerText={ null }
      className={ Classes.modal }
    >
      <div className={ Classes.about }>
        <h1>OUR COOKIES</h1>
        <p>
          We use cookies on our website to give you the best shopping experience.
        </p>
        <p>
          You can read more about our use of cookies in our&nbsp;
          <a href={ `${ window.currentLocale.basename }/privacy` }>Privacy Policy</a>
          , and allow them all or manage them individually.
        </p>
        <div className={ Classes.actions }>
          { actions }
        </div>
      </div>
      { renderDetails }
    </Modal>
  );
};

export default CookiesConsent;
