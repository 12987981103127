import React from 'react';
import PropTypes from 'prop-types';
import ArtItem from './art_item';
import Discount from './discount_item';
import ProductItem from './product_item';
import OriginalArtItem from './original_art_item';
import GiftCardItem from './gift_card_item';

const CartItem = ({ kind, discountText, ...rest }) => {
  if (kind === 'product') {
    return <ProductItem { ...rest } />;
  }
  if (kind === 'original') {
    return <OriginalArtItem { ...rest } />;
  }
  if (kind === 'giftcard') {
    return <GiftCardItem { ...rest } />;
  }
  // art
  if (discountText) {
    return <Discount discountText={ discountText } { ...rest } />;
  }
  return <ArtItem { ...rest } />;
};

CartItem.propTypes = {
  kind: PropTypes.string.isRequired,
  discountText: PropTypes.string
};

CartItem.defaultProps = {
  discountText: null
};

export default CartItem;
