import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg viewBox="0 0 24 24" { ...props }>
    <path
      d="M22.5 4h-6V3c0-1.66-1.34-3-3-3h-3c-1.66 0-3 1.34-3 3v1h-6c-.55 0-1 .45-1 1s.45 1 1 1h1.58l2.13 17h13.57l2.13-17h1.58c.55 0 1-.45 1-1s-.44-1-.99-1zm-13-1c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v1h-5V3zm.11 14h-.1a1 1 0 0 1-1-.9l-.61-6c-.05-.55.35-1.04.9-1.1a.995.995 0 0 1 1.1.89l.61 6c.05.56-.35 1.05-.9 1.11zm5.69-.9c-.05.52-.49.9-.99.9h-.1a1 1 0 0 1-.9-1.09l.59-6c.06-.56.52-.95 1.1-.91a1 1 0 0 1 .9 1.09l-.6 6.01z"
      fill={ fill }
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string.isRequired
};

export default SvgComponent;
