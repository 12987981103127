import snakeCase from 'lodash.snakecase';
import camelCase from 'lodash.camelcase';

const convertCase = (oldObject, converterFunction) => {
  let newObject;

  if (!oldObject || typeof oldObject !== 'object' || !Object.keys(oldObject).length) {
    return oldObject;
  }

  if (Array.isArray(oldObject)) {
    newObject = oldObject.map(element => convertCase(element, converterFunction));
  } else {
    newObject = {};
    Object.keys(oldObject).forEach(oldKey => {
      const newKey = converterFunction(oldKey);
      newObject[newKey] = convertCase(oldObject[oldKey], converterFunction);
    });
  }

  return newObject;
};

const toCamelCase = obj => convertCase(obj, camelCase);
const toSnakeCase = obj => convertCase(obj, snakeCase);

export {
  toCamelCase,
  toSnakeCase
};
