import { v4 as uuidv4 } from 'uuid';
import mixpanel from 'mixpanel-browser';

const BRAND = 'artfullywalls';
const USD = 'USD';
const GBP = 'GBP';
const CURRENCY_MAP = {
  '£': GBP,
  $: USD,
  uk: GBP,
  en: USD
};

const registerProps = (object, prefix) => {
  const props = {};
  Object.keys(object).forEach(key => {
    const name = key.split(/_/).join(' ');
    props[`${ prefix }${ name }`] = object[key];
  });
  mixpanel.register_once(props);
};

export const setupAnalytics = () => {
  const { mixpanelToken, currentUserId } = window;
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken);
    if (currentUserId) {
      mixpanel.identify(currentUserId);
    }
    if (window.utms) {
      registerProps(window.utms.c, '');
      registerProps(window.utms.o, 'original ');
    }
    mixpanel.register_once({
      'First Seen Date': new Date().toISOString()
    });
  }
};

export const trackEvent = window.mixpanelToken ? ((eventName, props = {}) => (
  mixpanel.track(eventName, {
    ...props,
    testGroup: window.testGroup,
    locale: window.currentLocale.currentLocale
  })
)) : (() => {});

export const acceptCookiesGTM = (values) => {
  window.dataLayer.push({
    event: 'consent_updated',
    cookies_accepted: values.join(',')
  });
};

const resetDataLayerEcommerce = () => {
  window.dataLayer.push({ ecommerce: { items: null } });
};

const wallPicturesToGA4Items = (wallPictures, wallId, position) => wallPictures.map(({
  // eslint-disable-next-line camelcase
  authorName: item_category,
  // eslint-disable-next-line camelcase
  title: item_name,
  // eslint-disable-next-line camelcase
  workId: item_id,
  workSizes,
  prices
}, index) => ({
  item_name,
  item_id,
  price: prices && prices.price,
  item_brand: BRAND,
  item_category,
  item_variant: workSizes[0] && workSizes[0].options && workSizes[0].options[0] ? (
    workSizes[0].options[0].gtmVariant
  ) : null,
  item_list_name: 'wall',
  item_list_id: `wall-${ wallId }`,
  index: index + 1 + (position || 0),
  quantity: '1'
}));

const wallPicturesToViewListItem = (wallPictures, currentPrice, wallId) => ({
  event: 'view_item_list',
  ecomm_prodid: wallPictures.map(({ workId }) => workId).join(','),
  ecomm_pagetype: 'product',
  ecomm_totalvalue: Number(currentPrice.amount),
  ecommerce: {
    currency: currentPrice.currency,
    items: wallPicturesToGA4Items(wallPictures, wallId)
  }
});

const wallPrice = ({ wallPictures, discountPercent }) => {
  const res = { currency: null, amount: 0 };
  const totalPrice = wallPictures
    .map(({ prices }) => prices.price)
    .reduce((sum, value) => sum + value, 0);
  const currentPrice = totalPrice * (1 - (discountPercent / 100)).toFixed(2);
  res.amount = Number(currentPrice.toFixed(2));
  res.currency = CURRENCY_MAP[wallPictures[0].prices.currency] || USD;
  return res;
};

const wallToGAEvent = ({ id, name }, event, pageType, currentPrice, position, key) => ({
  event,
  ecomm_prodid: `W${ id }`,
  ecomm_pagetype: pageType,
  ecomm_totalvalue: Number(currentPrice.amount),
  eventID: `W${ id }-${ uuidv4() }`,
  ecommerce: {
    currency: currentPrice.currency,
    [key]: {
      actionField: { list: `wall-${ id }` },
      products: [
        {
          name,
          id: `W${ id }`,
          price: Number(currentPrice.amount),
          brand: BRAND,
          variant: 'wall',
          position: 1 + (position || 0)
        }
      ]
    }
  }
});

const wallToAG4Event = ({ id, name }, event, pageType, currentPrice, position) => ({
  event,
  ecomm_prodid: `W${ id }`,
  ecomm_pagetype: pageType,
  ecomm_totalvalue: Number(currentPrice.amount),
  ecommerce: {
    currency: currentPrice.currency,
    items: [
      {
        item_name: name,
        item_id: `W${ id }`,
        price: currentPrice.amount,
        item_brand: BRAND,
        item_list_name: `wall-${ id }`,
        index: 1 + (position || 0),
        quantity: '1'
      }
    ]
  }
});

export const onWallClick = (wall, position) => {
  const currentPrice = wallPrice(wall);
  resetDataLayerEcommerce();
  window.dataLayer.push(wallToAG4Event(wall, 'select_item', 'category', currentPrice, position));
};

export const onWallImpression = (wall) => {
  const { id, wallPictures } = wall;
  const currentPrice = wallPrice(wall);
  window.dataLayer.push(wallPicturesToViewListItem(wallPictures, currentPrice, id));
  window.dataLayer.push(wallToAG4Event(wall, 'view_item', 'product', currentPrice));
  return wallToGAEvent(wall, 'productView', 'product', currentPrice, 'detail');
};

const workPictureToSelectItem = (workPicture, position, wallId) => ({
  event: 'select_item',
  ecomm_prodid: workPicture.workId,
  ecomm_pagetype: 'product',
  ecomm_totalvalue: workPicture.prices && workPicture.prices.price,
  ecommerce: {
    currency: CURRENCY_MAP[workPicture.prices.currency] || USD,
    items: wallPicturesToGA4Items([workPicture], wallId, position)
  }
});

export const onWallWorkPictureClick = (workPicture, wallId, position) => {
  resetDataLayerEcommerce();
  window.dataLayer.push(workPictureToSelectItem(workPicture, position, wallId));
};

const collectionItemsToGA4Items = (
  items, priceByItem, collectionId, position
) => items.map((item, index) => {
  if (item.kind === 'wall') {
    const {
      // eslint-disable-next-line camelcase
      title: item_name,
      // eslint-disable-next-line camelcase
      by: item_category,
      storableId
    } = item;
    return ({
      item_name,
      item_id: `W${ storableId }`,
      price: priceByItem(item),
      brand: BRAND,
      item_category,
      item_variant: 'wall',
      item_list_name: `collection-${ collectionId }`,
      position: index + 1 + (position || 0),
      quantity: '1'
    });
  }
  // art
  const {
    // eslint-disable-next-line camelcase
    title: item_name,
    // eslint-disable-next-line camelcase
    by: item_category,
    // eslint-disable-next-line camelcase
    storableId: item_id,
    workPicture
  } = item;
  return ({
    item_name,
    item_id,
    price: priceByItem(item),
    brand: BRAND,
    item_category,
    item_variant: workPicture.gtmVariant,
    item_list_name: `collection-${ collectionId }`,
    index: index + 1 + (position || 0),
    quantity: '1'
  });
});

const collectionItemsToViewListItem = (items, priceByItem, totalPrice, collectionId) => ({
  event: 'view_item_list',
  ecomm_prodid: items.map(i => `${ i.kind === 'wall' ? 'W' : '' }${ i.storableId }`).join(','),
  ecomm_pagetype: 'category',
  ecomm_totalvalue: Number(totalPrice.amount.toFixed(2)),
  ecommerce: {
    currency: totalPrice.currency,
    items: collectionItemsToGA4Items(items, priceByItem, collectionId)
  }
});

export const onCollectionImpression = ({ id, items, priceByItem }) => {
  const totalPrice = {
    amount: items.map((item) => priceByItem(item)).reduce((sum, value) => sum + value, 0),
    currency: CURRENCY_MAP[items[0] && items[0].locale] || USD
  };
  window.dataLayer.push(collectionItemsToViewListItem(items, priceByItem, totalPrice, id));
};

const collectionItemToSelectItem = (item, priceByItem, currency, collectionId, position) => ({
  event: 'select_item',
  ecomm_prodid: item.kind === 'work' ? item.storableId : `W${ item.storableId }`,
  ecomm_pagetype: 'category',
  ecomm_totalvalue: priceByItem(item),
  ecommerce: {
    currency,
    items: collectionItemsToGA4Items([item], priceByItem, collectionId, position)
  }
});

export const onCollectionItemClick = (item, priceByItem, collectionId, position) => {
  const currency = CURRENCY_MAP[item.locale] || USD;
  resetDataLayerEcommerce();
  window.dataLayer.push(collectionItemToSelectItem(
    item, priceByItem, currency, collectionId, position
  ));
};

const workItemsToUACollection = (items, { position, listName }) => items.map(({
  id,
  title: name,
  authorName: category,
  firstPaper: { gtmVariant: variant, retailPrice: price }
}, index) => ({
  name,
  id,
  brand: BRAND,
  category,
  variant,
  list: listName,
  position: index + 1 + (position || 0),
  price: Number(price)
}));

const workItemsToGA4Collection = (items, { position, listName }) => items.map(({
  // eslint-disable-next-line camelcase
  id: item_id,
  // eslint-disable-next-line camelcase
  title: item_name,
  // eslint-disable-next-line camelcase
  authorName: item_category,
  // eslint-disable-next-line camelcase
  firstPaper: { gtmVariant: item_variant, retailPrice: price }
}, index) => ({
  item_name,
  item_id,
  price,
  item_category,
  item_variant,
  item_list_name: listName,
  index: index + 1 + (position || 0),
  quantity: '1'
}));

const itemsToViewListItem = (items, listName, totalPrice, pageType) => ({
  event: 'view_item_list',
  ecomm_prodid: items.map(i => i.id).join(','),
  ecomm_pagetype: pageType,
  ecomm_totalvalue: Number(totalPrice.amount.toFixed(2)),
  ecommerce: {
    currency: totalPrice.currency,
    items: workItemsToGA4Collection(items, { listName })
  }
});

export const onWorkItems = ({ items, listName, pageType }) => {
  const totalPrice = {
    amount: items.map((item) => (item.firstPaper ? item.firstPaper.retailPrice : 0))
      .reduce((sum, value) => sum + value, 0),
    currency: CURRENCY_MAP[
      items[0] && items[0].currency ? items[0].currency : window.currentLocale.currentLocale
    ] || USD
  };
  window.dataLayer.push(itemsToViewListItem(items, listName, totalPrice, pageType));
};

const workToGAEvent = (event, work, pageType, listName, position, key) => ({
  event,
  ecomm_prodid: work.id,
  ecomm_pagetype: pageType,
  ecomm_totalvalue: work.firstPaper.retailPrice,
  eventID: `${ work.id }-${ uuidv4() }`,
  ecommerce: {
    currency: CURRENCY_MAP[
      work.currency ? work.currency : window.currentLocale.currentLocale
    ] || USD,
    [key]: {
      actionField: { list: listName },
      products: workItemsToUACollection([work], { listName, position })
    }
  }
});

const workToGA4Event = (event, work, pageType, listName, position) => ({
  event,
  ecomm_prodid: work.id,
  ecomm_pagetype: pageType,
  ecomm_totalvalue: work.firstPaper.retailPrice,
  ecommerce: {
    currency: CURRENCY_MAP[
      work.currency ? work.currency : window.currentLocale.currentLocale
    ] || USD,
    items: workItemsToGA4Collection([work], { listName, position })
  }
});

export const onWorkClick = (work, pageType, listName, position) => {
  resetDataLayerEcommerce();
  window.dataLayer.push(workToGA4Event('select_item', work, pageType, listName, position));
};

export const onWorkDetails = (work) => {
  window.dataLayer.push(workToGA4Event('view_item', work, 'product', 'product', 0));
  return workToGAEvent('productView', work, 'product', 'product', 0, 'detail');
};

const oaItemsToViewListItem = (items, totalPrice, pageType, listName) => ({
  event: 'view_item_list',
  ecomm_prodid: items.map(i => `OA${ i.id }`).join(','),
  ecomm_pagetype: pageType,
  ecomm_totalvalue: totalPrice,
  ecommerce: {
    currency: 'USD',
    items: items.map(({
      id,
      // eslint-disable-next-line camelcase
      name: item_name,
      user,
      price
    }, index) => ({
      item_name,
      item_id: `OA${ id }`,
      price: Number(price),
      item_category: user.name,
      item_list_name: listName,
      index: index + 1,
      quantity: '1'
    }))
  }

});

export const onOriginalArtItems = (items, pageType, listName) => {
  const totalPrice = items.map((item) => Number(item.price))
    .reduce((sum, value) => sum + value, 0);
  window.dataLayer.push(oaItemsToViewListItem(items, totalPrice, pageType, listName));
};

const orginalArtToGAEvent = (
  {
    id, price, name, user
  }, event, pageType, position
) => ({
  event,
  ecomm_prodid: `OA${ id }`,
  ecomm_pagetype: pageType,
  ecomm_totalvalue: Number(price),
  eventID: `OA${ id }-${ uuidv4() }`,
  ecommerce: {
    currency: 'USD',
    detail: {
      actionField: { list: 'product' },
      products: [
        {
          name,
          id: `OA${ id }`,
          price,
          brand: BRAND,
          category: user.name,
          position: 1 + (position || 0)
        }
      ]
    }
  }
});

const orginalArtToGA4Event = (
  {
    id, price, name, user
  }, event, pageType, position
) => ({
  event,
  ecomm_prodid: `OA${ id }`,
  ecomm_pagetype: pageType,
  ecomm_totalvalue: Number(price),
  ecommerce: {
    currency: 'USD',
    items: [
      {
        item_name: name,
        item_id: `OA${ id }`,
        price,
        item_brand: BRAND,
        item_category: user.name,
        item_list_name: 'Search Results',
        index: 1 + (position || 0),
        quantity: '1'
      }
    ]
  }
});

export const onOriginalArtDetails = (originalArt) => {
  resetDataLayerEcommerce();
  window.dataLayer.push(orginalArtToGA4Event(originalArt, 'select_item', 'product'));
};

export const onOriginalArtClick = (originalArt, position) => {
  window.dataLayer.push(orginalArtToGA4Event(originalArt, 'view_item', 'category', position));
  return orginalArtToGAEvent(originalArt, 'productView', 'category', position);
};

const cartItemToProduct = ({
  kind,
  price,
  by,
  name,
  // work
  workId,
  gtmVariant,
  // original art
  originalArtId
}) => {
  if (kind === 'art') {
    return ({
      name,
      id: workId,
      price: Number(price),
      brand: BRAND,
      category: by,
      variant: gtmVariant || 'other',
      quantity: 1
    });
  }
  if (kind === 'original') {
    // original
    return ({
      name,
      id: `OA${ originalArtId }`,
      price: Number(price),
      brand: BRAND,
      category: by,
      quantity: 1
    });
  }
  // other
  return ({
    name: name || 'other',
    price: Number(price),
    brand: BRAND,
    quantity: 1
  });
};

const cartItemToItem = ({
  kind,
  price,
  by,
  name,
  // work
  workId,
  gtmVariant,
  // original art
  originalArtId
}, index) => {
  if (kind === 'art') {
    return ({
      item_name: name,
      item_id: workId,
      price: Number(price),
      item_brand: BRAND,
      item_category: by,
      tem_variant: gtmVariant || 'other',
      index: index + 1,
      quantity: 1
    });
  }
  if (kind === 'original') {
    // original art
    return  ({
      item_name: name,
      item_id: `OA${ originalArtId }`,
      price: Number(price),
      item_brand: BRAND,
      item_category: by,
      index: index + 1,
      quantity: 1
    });
  }
  // other
  return  ({
    item_name: name || 'other',
    price: Number(price),
    item_brand: BRAND,
    index: index + 1,
    quantity: 1
  });
};

export const onUserNewletterSubscribed = (customerHashedEmail, eventId) => {
  window.dataLayer.push({
    event: 'generate_lead',
    customerHashedEmail,
    eventID: eventId
  });
};

export const onAddedToCart = (items, analyticsEventId) => {
  const reportingItems = items.filter(({
    workId, originalArtId
  }) => (workId || originalArtId));
  const ids = reportingItems.map(({
    workId, originalArtId
  }) => (workId || originalArtId)).join(',');

  const totalPrice = reportingItems.map((item) => Number(item.price))
    .reduce((sum, value) => sum + value, 0);

  resetDataLayerEcommerce();

  const currency = CURRENCY_MAP[window.currentLocale.currentLocale];

  window.dataLayer.push({
    event: 'add_to_cart',
    ecomm_prodid: ids,
    ecomm_pagetype: 'cart',
    ecomm_totalvalue: totalPrice,
    eventID: analyticsEventId,
    ecommerce: {
      currency,
      items: items.map(cartItemToItem)
    }
  });
};

const CHECKOUT_STEPS = [
  [],
  ['view cart', 'view_cart'],
  ['new user', 'begin_checkout', 'conversionInitiateCheckoutEventId'],
  ['shiping info', 'add_shipping_info'],
  ['payment info', 'add_payment_info', 'conversionAddPaymentInfoEventId']
];

export const onCheckoutStep = (step, items) => {
  const totalPrice = items.map((item) => Number(item.price))
    .reduce((sum, value) => sum + value, 0);

  const ids = items.filter(({
    workId, originalArtId
  }) => (workId || originalArtId)).map(({
    workId, originalArtId
  }) => (workId || originalArtId)).join(',');
  const currency = CURRENCY_MAP[window.currentLocale.currentLocale];

  const eventId = CHECKOUT_STEPS[step][2] && window[CHECKOUT_STEPS[step][2]] || null;

  window.dataLayer.push({
    event: CHECKOUT_STEPS[step][1],
    eventID: eventId,
    ecomm_prodid: ids,
    ecomm_pagetype: 'cart',
    ecomm_totalvalue: totalPrice,
    ecommerce: {
      currency,
      items: items.map(cartItemToItem)
    }
  });
};

export const onRemovedFromCart = (item) => {
  const currency = CURRENCY_MAP[window.currentLocale.currentLocale];
  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      currency,
      remove: { products: cartItemToProduct(item, 0) }
    }
  });
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency,
      items: cartItemToItem(item, 0)
    }
  });
};

export const onOrderPlaced = (cartItems, {
  transactionId,
  affiliation,
  revenue,
  tax,
  shipping,
  promoCode
}, customerHashedEmail, analyticsEventId) => {
  const ids = cartItems.filter(({
    workId, originalArtId
  }) => (workId || originalArtId)).map(({
    workId, originalArtId
  }) => (workId || originalArtId)).join(',');
  const currency = CURRENCY_MAP[window.currentLocale.currentLocale];

  window.dataLayer.push({
    event: 'purchase',
    ecomm_prodid: ids,
    ecomm_pagetype: 'purchase',
    ecomm_totalvalue: revenue,
    customerHashedEmail,
    eventID: analyticsEventId,
    ecommerce: {
      transaction_id: transactionId,
      currency,
      value: revenue,
      tax,
      affiliation,
      shipping,
      coupon: promoCode,
      items: cartItems.map(cartItemToItem)
    }
  });
};

export const onWallSaved = (numItemsWall) => {
  window.dataLayer.push({
    event: 'save_wall',
    numItemsWall
  });
};

export const onWallShared = (numItemsWall) => {
  window.dataLayer.push({
    event: 'share_wall',
    numItemsWall
  });
};
