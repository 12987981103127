import React from 'react';
import { createPortal } from 'react-dom';
import CartItemsPopup from './smart_cart_items_popup';

export default () => {
  const p = document.getElementById('shopping-cart-popup2');
  if (p) {
    return createPortal(<CartItemsPopup />, p);
  }
  return null;
};
