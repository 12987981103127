import React from 'react';
import PropTypes from 'prop-types';

const SvgComponent = ({ fill, ...props }) => (
  <svg viewBox="0 0 24 24" { ...props }>
    <path
      d="M24 13.88v-3.75h-3.46a8.59 8.59 0 0 0-1.18-2.84l2.45-2.45-2.65-2.65-2.45 2.45c-.86-.55-1.81-.95-2.84-1.18V0h-3.75v3.46c-1.02.22-1.98.63-2.83 1.18L4.84 2.19 2.19 4.84l2.45 2.45c-.55.85-.96 1.81-1.18 2.83H0v3.75h3.46a8.59 8.59 0 0 0 1.18 2.84l-2.45 2.45 2.65 2.65 2.45-2.45c.86.55 1.81.95 2.84 1.18V24h3.75v-3.46a8.59 8.59 0 0 0 2.84-1.18l2.45 2.45 2.65-2.65-2.45-2.45c.55-.86.95-1.81 1.18-2.84H24zM12 16.5c-2.49 0-4.5-2.01-4.5-4.5S9.51 7.5 12 7.5s4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z"
      fill={ fill }
    />
  </svg>
);

SvgComponent.propTypes = {
  fill: PropTypes.string.isRequired
};

export default SvgComponent;
