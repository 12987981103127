import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Classes from './styles';

const RemoveCartItem = ({ removeCartItem, id }) => {
  const [removing, setRemoving] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    setRemoving(true);
    removeCartItem(id);
  };

  const content = removing ? (
    <Loader active inline />
  ) : (
    <button type="button" onClick={ onClick }>
      Remove Item
    </button>
  );

  return (
    <div className={ Classes.remove }>
      { content }
    </div>
  );
};

RemoveCartItem.propTypes = {
  removeCartItem: PropTypes.func,
  id: PropTypes.number.isRequired
};

export default RemoveCartItem;
