import numeral from 'numeral';

numeral.register('locale', 'uk', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  currency: {
    symbol: '£'
  }
});

export default numeral;
