/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import T from 'prop-types';

import Radio from 'arts/components/Radio';
import Classes from './styles';

export const FULL_BLEED = 'fullBleed';
export const MATTE = 'matte';
export const FLOAT_DECKLED = 'dckl';
export const FLOAT_STRIGHT = 'fltn';
export const FLOATING = 'floating';

export const mat = () => (window.currentLocale.currentLocale === 'en' ? '2.75"' : '7cm');

const matte = (caps) => (caps ? 'M' : 'm') + (window.currentLocale.currentLocale === 'en' ? 'atte' : 'ount');

const mountDetails = () => [
  { kind: MATTE,       name: `With ${ matte(true) }`,  description:  ` - ${ mat() } wide, white matboard, buffered for extended life` },
  { kind: FULL_BLEED,  name: `No ${ matte(true) }`,    description: ` - no ${ matte(false) } or border, image is flush to frame` },
  {
    kind: FLOATING,
    name: 'Float Mount',
    description: ' - image floated on 1.25" wide white matte'
  }
];

const floatStright = {
  kind: FLOAT_STRIGHT, name: 'Straight Edge', description: ' - straight paper edges'
};
const floatDeckled = {
  kind: FLOAT_DECKLED, name: 'Deckled Edge', description: ' - rough paper edges'
};

const VALID_MOUNT_KINDS = [MATTE, FULL_BLEED, FLOAT_STRIGHT, FLOAT_DECKLED];

class Mounts extends PureComponent {
  static propTypes = {
    onSelect: T.func.isRequired,
    selected: T.oneOf(VALID_MOUNT_KINDS),
    paperId: T.number.isRequired,
    matte: T.bool,
    fillBleed: T.bool,
    dckl: T.bool,
    fltn: T.bool
  };

  static defaultProps = {
    matte: false,
    fillBleed: false,
    dckl: false,
    fltn: false,
    selected: null
  };

  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
    this.availableMounts = this.availableMounts.bind(this);
    this.renderMount = this.renderMount.bind(this);
  }

  onChange(e) {
    const { onSelect } = this.props;

    const {
      value
    } = e.target;

    if (value === FLOATING) {
      onSelect(FLOAT_STRIGHT);
    } else {
      onSelect(value);
    }
  }

  availableMounts({ kind }) {
    // eslint-disable-next-line react/destructuring-assignment
    if (kind === FLOATING) {
      return this.props[FLOAT_STRIGHT] || this.props[FLOAT_DECKLED];
    }
    return this.props[kind] === true;
  }

  renderMount({ kind, name, description }, overrideName = null) {
    const {
      paperId,
      selected
    } = this.props;

    const radioId = `MountRadioControl-${ paperId }-${ kind }`;
    const radioName = `MountRadioControl-${ paperId }-${ overrideName }`;

    const isSelected = selected === kind || (
      kind === FLOATING && (selected === FLOAT_STRIGHT || selected === FLOAT_DECKLED)
    );

    let extraMounts = null;
    if (kind === FLOATING && isSelected) {
      extraMounts = (
        <div className={ Classes.floatForm }>
          { this.renderMount(floatStright, 'float') }
          { this.renderMount(floatDeckled, 'float') }
        </div>
      );
    }

    return (
      <div key={ kind }>
        <label
          htmlFor={ radioId }
          key={ kind }
        >
          <div className={ Classes.mountOption }>
            <Radio
              type="radio"
              value={ kind }
              checked={ isSelected }
              onChange={ this.onChange }
              id={ radioId }
              name={ radioName }
            />

            <div className={ Classes.mount }>
              { name }
              <span>
                { description }
              </span>
            </div>
          </div>
        </label>
        { extraMounts }
      </div>
    );
  }

  render() {
    return (
      <form className={ Classes.mounts }>
        { mountDetails().filter(this.availableMounts).map(this.renderMount) }
      </form>
    );
  }
}

export default Mounts;
