import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import { toCamelCase } from 'arts/config/convertCase';

const getDefaultState = () => {
  const {
    cartItems: collection, summaryOfCharges, locale, pendingCartItemsCount
  } = toCamelCase(window.cartDetails || {});
  return ({
    collection: collection || [],
    loading: false,
    error: null,
    summaryOfCharges: summaryOfCharges || {},
    savedItem: null,
    locale,
    adding: false,
    added: false,
    pendingCartItemsCount
  });
};

export default createReducer(getDefaultState(), {
  [Constants.CartItems.FETCH_SUCCESS]: (state, {
    collection, summaryOfCharges, locale, savedItem, pendingCartItemsCount
  }) => ({
    ...state,
    loading: false,
    error: null,
    collection,
    summaryOfCharges,
    savedItem,
    locale,
    pendingCartItemsCount
  }),
  [Constants.CartItems.RESET_SAVED]: (state) => ({
    ...state,
    savedItem: null
  }),
  [Constants.CartItems.FETCH_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
    collection: [],
    summaryOfCharges: {}
  }),
  [Constants.CartItems.ADDING]: (state) => ({
    ...state,
    adding: true,
    added: false
  }),
  [Constants.CartItems.ADDED]: (state) => ({
    ...state,
    adding: false,
    added: true
  }),
  [Constants.CartItems.RESET_ADDED]: (state) => ({
    ...state,
    adding: false,
    added: false
  })
});
