import livingRoom from '../components/assets/living-room.gif';
import console from '../components/assets/console.gif';
import chaise from '../components/assets/chaise.gif';
import livingRoomSmall from '../components/assets/living-room-small.png';
import consoleSmall from '../components/assets/console-small.png';
import chaiseSmall from '../components/assets/chaise-small.png';
import PreviewConst from '../../shared/preview_const';

const Const = {
  hangings: {
    load: 'hangings:load',
    loaded: 'hangings:loaded',
    update: 'hangings:update',
    reset: 'hangings:reset',
    saving: 'hangings:saving',
    saved: 'hangings:saved'
  },
  pin: {
    toggle: 'pin:toggle'
  },
  crop: {
    set: 'crop:set',
    reset: 'crop:reset'
  },
  savingWall: {
    start: 'wall:saving:start',
    end: 'wall:saving:end',
    uploadAssetsStart: 'wall:saving:uploadAssets:start',
    uploadAssetsEnd: 'wall:saving:uploadAssets:end'
  },
  panel: {
    open: 'panel:open',
    close: 'panel:close'
  },
  favoriteWorks: {
    load: 'favoriteWorks:load',
    success: 'favoriteWorks:load:success'
  },
  works: {
    load: 'works:load',
    success: 'works:load:success',
    filtersLoaded: 'works:filters:loaded',
    addFilter: 'works:filter:add',
    removeFilter: 'works:filter:remove'
  },
  work: {
    load: 'work:load',
    success: 'work:load:success'
  },
  panelKinds: {
    works: 'panel:kind:works',
    other: 'panel:kind:other',
    grids: 'panel:kind:grids',
    export: 'panel:kind:export',
    info: 'panel:kind:info',
    details: 'panel:kind:details',
    moreLikeThis: 'panel:kind:moreLikeThis',
    customPreview: 'panel:kind:customPreview',
    hanging: 'panel:kind:hanging'
  },
  moreLikeThisSources: {
    works: 'moreLikeThisSources:works',
    more: 'moreLikeThisSources:more',
    canvas: 'moreLikeThisSources:canvas'
  },
  export: {
    savePreview: 'export:preview:save',
    previewSaved: 'export:preview:saved',
    showSharing: 'export:sharing:showSharing',
    setLogo: 'export:setLogo',
    publishing: 'export:publishing',
    published: 'export:published'
  },
  canvas: {
    add: 'canvas:add',
    remove: 'canvas:remove',
    move: 'canvas:move',
    zoomIn: 'canvas:zoomIn',
    zoomOut: 'canvas:zoomOut',
    setSize: 'canvas:setWorkSize',
    setFrame: 'canvas:setFrame',
    setCenter: 'canvas:setCenter',
    resetZoom: 'canvas:resetZoom',
    setSilhouette: 'canvas:setSilhouette',
    hideSilhouette: 'canvas:hideSilhouette',
    setColor: 'canvas:setColor',
    showRuler: 'canvas:showRuler',
    hideRuler: 'canvas:hideRuler',
    showGuides: 'canvas:showGuides',
    hideGuides: 'canvas:hideGuides',
    showWidth: 'canvas:showWidth',
    hideWidth: 'canvas:hideWidth',
    showHeight: 'canvas:showHeight',
    hideHeight: 'canvas:hideHeight',
    setWidth: 'canvas:setWidth',
    setHeight: 'canvas:setHeight',
    recalculateDpis: 'canvas:recalculateDpis',
    resetCenter: 'canvas:resetCenter',
    updateAttributes: 'canvas:updateAttributes'
  },
  defaultColor: '#ffffff',
  customImage: {
    add: 'customImage:add',
    remove: 'customImage:remove',
    move: 'customImage:move',
    update: 'customImage:update'
  },
  wallPaper: {
    add: 'wallPaper:add',
    remove: 'wallPaper:remove',
    update: 'wallPaper:update'
  },
  silhouette: {
    // width: 158.33,
    width: 230, // 158.33 - 1881
    height: 172.3, // 118.403304348 - 1410
    // height: 118.40,
    whitePos: 130,
    zero: 140.4,
    // whitePos: 89.49,
    mappingSmall: {
      livingRoom: livingRoomSmall, console: consoleSmall, chaise: chaiseSmall
    },
    mapping: {
      livingRoom, console, chaise
    },
    default: 'livingRoom'
  },
  undo: {
    pop: 'undo:pop',
    push: 'undo:push'
  },
  workDetails: {
    show: 'workDetails:show',
    hide: 'workDetails:hide'
  },
  moreLikeThis: {
    set: 'moreLikeThis:set',
    reset: 'moreLikeThis:reset',
    loading: 'moreLikeThis:loading',
    loaded: 'moreLikeThis:loaded'
  },
  preview: PreviewConst,
  dpi: {
    minRulers: 2.25,
    maxRulers: 288,
    rulers: {
      2.25: {
        step: 10,
        stepCm: 25,
        labelStep: 20,
        labelStepCm: 50,
        guideStep: 5
      },
      4.5: {
        step: 5,
        stepCm: 20,
        labelStep: 10,
        labelStepCm: 25,
        guideStep: 5
      },
      9: {
        step: 5,
        stepCm: 25,
        labelStep: 5,
        labelStepCm: 10,
        guideStep: 2.5
      },
      18: {
        step: 1,
        stepCm: 5,
        labelStep: 5,
        labelStepCm: 10,
        guideStep: 1
      },
      36: {
        step: 1,
        stepCm: 5,
        labelStep: 5,
        labelStepCm: 10,
        guideStep: 0.5
      },
      72: {
        step: 1,
        stepCm: 5,
        labelStep: 5,
        labelStepCm: 10,
        guideStep: 0.5
      },
      144: {
        step: 1,
        stepCm: 5,
        labelStep: 1,
        labelStepCm: 10,
        guideStep: 0.5
      },
      288: {
        step: 1,
        stepCm: 5,
        labelStep: 1,
        labelStepCm: 10,
        guideStep: 0.5
      }
    }
  }
};

export default Const;
