import React, { useState, useEffect } from 'react';
import Modal from 'arts/components/Modal';
import Cookies from 'js-cookie';
import { MODAL_SELECTOR } from 'arts/config/constants';
import Classes from './styles';
import Uk from './uk';
import Us from './us';
import { request } from '../request';

export const SET_LOCALE = 'setlocale';

const LocaleSelection = () => {
  const [isShown, setShow] = useState(false);
  const [altUrl, setAltUrl] = useState(null);

  useEffect(() => {
    const link = document.querySelector('link[rel="alternate"]');
    if (link) {
      const href = link.getAttribute('href');
      if (href) { setAltUrl(href); }
    }
  }, []);

  const show = () => setShow(true);
  const hide = () => setShow(false);

  useEffect(() => {
    document.addEventListener(SET_LOCALE, show, { capture: true });
    return () => {
      document.removeEventListener(SET_LOCALE, show, { capture: true });
    };
  }, []);

  if (!isShown) { return null; }

  const [site, otherPath, otherSite, Img] = window.currentLocale.currentLocale === 'uk'
    ? ['UK', (altUrl || '/en'), 'US', Uk]
    : ['US', (altUrl || '/uk'), 'UK', Us];

  let usLink = '/en';
  let ukLink = '/uk';
  if (window.currentLocale.currentLocale === 'uk' && altUrl) {
    usLink = altUrl;
  } else if (window.currentLocale.currentLocale === 'en' && altUrl) {
    ukLink = altUrl;
  }

  const goUk = (e) => {
    e.preventDefault();
    request(
      '/locales', {
        method: 'POST',
        body: JSON.stringify({ locale: 'uk' })
      }
    ).then(() => {
      Cookies.set('locale', 'uk', { expires: 365 });
      window.location.href = ukLink;
    });
  };

  const goUs = () => {
    Cookies.set('locale', 'en', { expires: 365 });
    return true;
  };

  const goOther = (e) => {
    if (otherSite === 'US') {
      return goUs(e);
    }
    return goUk(e);
  };

  return (
    <Modal
      selector={ MODAL_SELECTOR }
      onCancel={ hide }
    >
      <div className={ Classes.locale }>
        <a href={ usLink } onClick={ goUs }>
          <h5>USA / International</h5>
          <p>
            In addition to shipping within the United States, we ship internationally to Canada,
            Australia and Ireland. Prices and payment are shown in US Dollars (USD).
          </p>
        </a>
        <div className={ Classes.div } />
        <a href={ ukLink } onClick={ goUk }>
          <h5>UNITED KINGDOM</h5>
          <p>
            Artfully Walls ships within the United Kingdom.
            Prices and payment are shown in Great Britain Pounds (GBP).
          </p>
        </a>
        <div className={ Classes.current }>
          Your are currently shopping our&nbsp;
          { site }
          &nbsp;
          <Img />
          &nbsp;site.
          <a href={ otherPath } onClick={ goOther }>
            Go to our&nbsp;
            { otherSite }
            &nbsp;site
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default LocaleSelection;
