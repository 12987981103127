import React from 'react';

export const BY_COLOR = 'by_color';

export const renderColorSelection = (
  onSelect, options, classLine, classColor
) => options.map((line, index) => (
  <div key={ index } className={ classLine }>
    {
      line.map((color, cindex) => (
        <div
          className={ classColor }
          style={ { backgroundColor: `#${ parseInt(color, 10).toString(16).padStart(6, '0') }` } }
          key={ cindex }
          type="button"
          onClick={ () => onSelect(color) }
        >
          &nbsp;
        </div>
      ))
    }
  </div>
));
