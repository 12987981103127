import React from 'react';
import T from 'prop-types';
import Classes from './styles';

const Content = ({ children, ...rest }) => (
  <div className={ Classes.contentDefault } { ...rest }>
    { children }
  </div>
);

Content.propTypes = {
  children: T.node
};

Content.defaultProps = {
  children: null
};

export default Content;
