import snakeCase from 'lodash.snakecase';
import camelCase from 'lodash.camelcase';

const convertCase = (oldObject, converterFunction) => {
  let newObject;

  if (!oldObject || typeof oldObject !== 'object' || !Object.keys(oldObject).length) {
    return oldObject;
  }

  if (Array.isArray(oldObject)) {
    newObject = oldObject.map(element => convertCase(element, converterFunction));
  } else {
    newObject = {};
    Object.keys(oldObject).forEach(oldKey => {
      const newKey = converterFunction(oldKey);
      newObject[newKey] = convertCase(oldObject[oldKey], converterFunction);
    });
  }

  return newObject;
};

export const toCamelCase = obj => convertCase(obj, camelCase);
export const toSnakeCase = obj => convertCase(obj, snakeCase);

export const humanSizes = (landscape, paperWidth, paperHeight) => {
  const sizes = [paperWidth, paperHeight];
  const { unit } = window.currentLocale;
  return `${ (landscape ? sizes.sort((a, b) => (a > b ? -1 : 1)) : sizes.sort((a, b) => (a < b ? -1 : 1))).join('x') }${ unit }`;
};

// TODO: use reselect
export const calculateTotalPrice = (works, workDetails) => works.reduce((a, w) => {
  const { workSizeId, frameId, workId } = w;
  const { workSizes } = workDetails[workId] || {};
  if (!workSizes) { return 0; }
  const workSize = workSizes.find((ws) => ws.id === workSizeId);
  const option = workSize.options.find((o) => o.frameId === (frameId || null))
    || workSize.options.find((o) => o.frameId === null);
  return a + parseFloat(option.price);
}, 0);

export const calculateCanvasSize = () => {
  const { innerHeight, innerWidth } = window;
  return {
    cWidth: innerWidth,
    cHeight: innerHeight - 111 - 60 // site header + toolbar
  };
};

export const screenToDefaultDpi = ({ width, height }) => {
  const { cWidth, cHeight } = calculateCanvasSize();
  const dpiX = cWidth / width;
  const dpiY = cHeight / height; // technical page header and toolbar
  return Math.min(dpiX, dpiY);
};

export const sortedHumanSizes = (workSizes, landscape) => workSizes
  .sort((a, b) => {
    const { paperWidth: w0, paperHeight: h0 } = a;
    const { paperWidth: w1, paperHeight: h1 } = b;
    const s0 = w0 * h0;
    const s1 = w1 * h1;
    return s0 < s1 ? -1 : 1;
  })
  .map((ws) => [humanSizes(landscape, ws.displayPaperWidth, ws.displayPaperHeight), ws]);
