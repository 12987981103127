import { connect } from 'react-redux';
import { resetSavedItem } from 'arts/actions/cart_items';
import CartItemsPopup from './cart_items_popup';

const mapStateToProps = ({
  cartItems: {
    savedItem, collection, summaryOfCharges: { pendingCartItemsCount, merchandise }, locale
  }
}) => ({
  collection,
  savedItem,
  pendingCartItemsCount,
  merchandise,
  locale
});

export default connect(mapStateToProps, { resetSavedItem })(CartItemsPopup);
