const Constants = {
  locales: {
    uk: 'uk'
  },

  applePaySession: {
    SUCCESS: 'applePaySession:success'
  },

  checkoutProcess: {
    setMethod: 'checkoutProcess:setMethod',
    process: 'checkoutProcess:process',
    placementError: 'checkoutProcess:placementError'
  },

  Expandable: {
    KEYS: {
      additionalInfo: 'e:k:additional',
      selectionDetails: 'e:k:selection',
      about: 'e:k:about',
      prints: 'e:k:prints',
      frames: 'e:k:frames',
      shipping: 'e:k:shipping',
      frameDetails: 'e:k:frameDetails'
    },
    ACTIONS: {
      expand: 'e:actions:expand'
    }
  },
  Work: {
    FETCH:          'work:fetch:start',
    FETCH_SUCCESS:  'work:fetch:success',
    FETCH_FAILURE:  'work:fetch:failure'
  },

  AuthorOriginalArt: {
    FETCH:          'author_original_art:fetch:start',
    FETCH_SUCCESS:  'author_original_art:fetch:success'
  },

  OriginalArt: {
    FETCH:          'original_art:fetch:start',
    FETCH_SUCCESS:  'original_art:fetch:success'
  },

  Works: {
    FETCH:          'works:fetch:start',
    FETCH_SUCCESS:  'works:fetch:success',
    FETCH_FAILURE:  'works:fetch:failure',
    RESET:          'works:reset'
  },

  Tapestries: {
    FETCH:          'tapestries:fetch:start',
    FETCH_SUCCESS:  'tapestries:fetch:success',
    FETCH_FAILURE:  'tapestries:fetch:failure',
    RESET:          'tapestries:reset'
  },

  ExploreArt: {
    RESET:          'explore_art:reset',
    FETCH:          'explore_art:fetch:start',
    FETCH_SUCCESS:  'explore_art:fetch:success'
  },

  Artists: {
    RESET:          'artists:reset',
    FETCH:          'artists:fetch:start',
    FETCH_SUCCESS:  'artists:fetch:success'
  },

  OrginalArts: {
    FETCH:          'orginal_arts:fetch:start',
    FETCH_SUCCESS:  'orginal_arts:fetch:success',
    RESET:          'orginal_arts:reset'
  },

  MyWalls: {
    FETCH: 'mywalls:fetch:start',
    FETCH_SUCCESS: 'mywalls:fetch:success',
    DELETE: 'mywalls:delete:start',
    DELETE_SUCCESS: 'mywalls:delete:success',
    DELETE_FAILURE: 'mywalls:delete:failure'
  },

  Walls: {
    FETCH:          'walls:fetch:start',
    FETCH_SUCCESS:  'walls:fetch:success',
    RESET:          'walls:reset'
  },

  Wall: {
    FETCH: 'wall:fetch:start',
    FETCH_SUCCESS: 'wall:fetch:success'
  },

  ArtistWorks: {
    FETCH:          'artist_works:fetch:start',
    FETCH_SUCCESS:  'artist_works:fetch:success',
    FETCH_FAILURE:  'artist_works:fetch:failure'
  },

  ArtFilters: {
    FETCH_SUCCESS:  'art_filters:fetch:success'
  },

  ArtistFilters: {
    FETCH_SUCCESS:  'artist_filters:fetch:success'
  },

  OriginalArtFilters: {
    FETCH_SUCCESS:  'oa_filters:fetch:success'
  },

  WallFilters: {
    FETCH_SUCCESS:  'wall_filters:fetch:success'
  },

  Selection: {
    SET_FRAME:  'selection:frame:set',
    SET_NON_GLARE: 'selection:nonglare:set',
    RESET:      'selection:frame:reset',
    SET_OTHER:  'selection:other:image',
    RESET_OTHER:'selection:other:reset'
  },

  Collections: {
    LOAD: 'collections:load',
    LOADED: 'collections:loaded'
  },

  CollectionDetails: {
    LOAD: 'collectionDetails:load',
    LOADED: 'collectionDetails:loaded'
  },

  DesignerPicks: {
    LOAD: 'designer_picks:load',
    LOADED: 'designer_picks:loaded'
  },

  GiftGuides: {
    LOAD: 'gift_guides:load',
    LOADED: 'gift_guides:loaded'
  },

  Favorites: {
    ADD:        'favorites:add',
    REMOVE:     'favorites:remove',
    LOAD:       'favorites:load',
    LOADED:     'favorites:loaded'
  },

  ArtistInfo: {
    FETCH:          'artist-info:fetch:start',
    FETCH_SUCCESS:  'artist-info:fetch:success'
  },

  Artist: {
    FETCH:          'artist:fetch:start',
    FETCH_SUCCESS:  'artist:fetch:success',
    FETCH_FAILURE:  'artist:fetch:failure'
  },

  RelatedWorks: {
    LOAD_ALSO_LIKE_START:         'related_works:load_also_like:start',
    LOAD_ALSO_LIKE_SUCCESS:       'related_works:load_also_like:success',
    LOAD_ALSO_LIKE_FAILURE:       'related_works:load_also_like:failure',

    LOAD_RECENTLY_VIEW_START:     'related_works:recently_view:start',
    LOAD_RECENTLY_VIEW_SUCCESS:   'related_works:recently_view:success',
    LOAD_RECENTLY_VIEW_FAILURE:   'related_works:recently_view:failure',

    SET_RECENTLY_VIEWED:          'related_works:recently_view:set_ids'
  },
  SimilarWorks: {
    FETCH:          'product:similar_works:start',
    FETCH_SUCCESS:  'product:similar_works:success'
  },
  Product: {
    FETCH:          'product:fetch:start',
    FETCH_SUCCESS:  'product:fetch:success',
    FETCH_FAILURE:  'product:fetch:failure'
  },
  ProductsList: {
    FETCH:          'products:list:fetch:start',
    FETCH_SUCCESS:  'products:list:fetch:success',
    FETCH_FAILURE:  'products:list:fetch:failure'
  },
  WorkStoreItems: {
    ADDED:    'work_store_items:added',
    REMOVED:  'work_store_items:removed'
  },
  WallStoreItems: {
    ADDED:    'work_store_items:added',
    REMOVED:  'work_store_items:removed'
  },
  GiftCardBalance: {
    FETCH:          'GiftCardBalance:fetch:start',
    FETCH_SUCCESS:  'GiftCardBalance:fetch:success',
    FETCH_FAILURE:  'GiftCardBalance:fetch:failure',
    CLEAR:          'GiftCardBalance:clear'
  },

  CartItems: {
    FETCH_SUCCESS: 'CartItems:fetch:success',
    FETCH_FAILURE: 'CartItems:fetch:failure',
    RESET_SAVED: 'CartItems:resetSaved:failure',
    ADDING: 'CartItems:adding',
    ADDED: 'CartItems:added',
    RESET_ADDED: 'CartItems:added:reset'
  },

  MailSubscriptions: {
    DISALLOW: 'MailSubscriptions:disallow'
  }
};

export const MODAL_SELECTOR = '#modal-root';
export const MODAL_LEVEL2_SELECTOR = '#modal-level2';

export const CANVAS = 'Canvas';
export const TAPESTRY = 'Olefin Fiber Paper';

export default Constants;
