const PreviewConst = {
  ratio: 1.66666666,
  width: 1200,
  height: 720,
  scaleX: 158.33,
  scaleY: 95.0
};

export const scaledPreviewDmentions = (previewWidth, previewHeight, dpi) => ({
  width: ((previewWidth || 0) * PreviewConst.scaleX * dpi) / 100,
  height: ((previewHeight || 0) * PreviewConst.scaleY * dpi) / 100
});

export default PreviewConst;
