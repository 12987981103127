import React from 'react';

export const shippingDetailsItems = () => {
  const shippingItems = [(
    <p key="sr">
      Shipping rates are calculated at time of checkout. In
      order to view your shipping rate, you can start the
      checkout process, enter your shipping address,
      and the relevant rate will appear.
    </p>
  )];
  if (window.currentLocale.currentLocale !== 'uk') {
    shippingItems.push((
      <p key="t2">
        We`re happy to announce that we now ship to Canada, Australia and Ireland as well.
      </p>
    ));
  }
  const duties = (window.currentLocale.currentLocale === 'uk')
    ?  null
    : ' Shipping does not include taxes, duties or any other fees that may be collected at time of delivery';
  shippingItems.push((
    <p key="t3">
      All of our art, regardless of whether it
      is framed or not, ships within 10-14 business days.
      { duties }
    </p>
  ));
  if (window.currentLocale.currentLocale !== 'uk') {
    shippingItems.push((
      <p key="t4">
        Unframed prints ship in tubes, and framed prints and canvases in boxes,
        providing extra protection and safe delivery.
      </p>
    ));
    shippingItems.push((
      <p key="t5">
        As our prints and frames are custom made to order according
        to your specifications, all of our products are final sale and non-returnable.
      </p>
    ));
  }
  return shippingItems;
};
