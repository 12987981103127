import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import classes from './classes';
import Settings from '../assets/settings';
import Trash from '../assets/trash';
import WorkPictureImage from './work_picture_image';

export default class Work extends PureComponent {
  static propTypes = {
    workId: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    dpi: PropTypes.number.isRequired,
    dragging: PropTypes.bool.isRequired,

    title: PropTypes.string,
    workSizeId: PropTypes.number.isRequired,
    frameId: PropTypes.number,
    landscape: PropTypes.bool,

    workSizes: PropTypes.array,
    loadWorkDetails: PropTypes.func,
    moveWorkTo: PropTypes.func,
    removeFromCanvas: PropTypes.func,
    onClick: PropTypes.func,

    readOnly: PropTypes.bool
  };

  static defaultProps = {
    readOnly: false,
    loadWorkDetails: null,
    moveWorkTo: null,
    removeFromCanvas: null,
    onClick: null
  };

  static defaultProps = {
    frameId: null,
    title: null,
    landscape: false
  };

  static defaultProps = {
    workSizes: []
  };

  componentDidMount() {
    const { workId, workSizes, loadWorkDetails } = this.props;
    if (!workSizes.length) {
      loadWorkDetails(workId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      x, y, moveWorkTo, index
    } = this.props;
    if (!moveWorkTo) {
      return;
    }
    if (prevProps.x !== x || prevProps.y !== y) {
      moveWorkTo({ x, y, index });
    }
  }

  onClick = () => {
    const { index, onClick } = this.props;
    onClick(index);
  }

  removeWork = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { index, removeFromCanvas } = this.props;
    removeFromCanvas(index);
  }

  findWorkSize() {
    const { workSizeId, workSizes } = this.props;
    const res = workSizes.find((ws) => ws.id === workSizeId);
    return res || (workSizes || [])[0];
  }

  findFramedOption() {
    const { frameId } = this.props;
    const workSize = this.findWorkSize();
    if (!workSize) {
      return {};
    }
    const fId = frameId || null;
    return workSize.options.find((o) => o.frameId === fId)
      || workSize.options.find((o) => o.frameId === null);
  }

  render() {
    const {
      workSizes, title, dragging, readOnly
    } = this.props;

    if (!workSizes.length) {
      return <Loader active inline />;
    }

    const frameOption = this.findFramedOption();

    const controls = readOnly ? null : (
      <>
        <button className={ classes.cog } type="button" onClick={ this.onClick } aria-label="Settings">
          <Settings fill="#fff" />
        </button>
        <button className={ classes.remove } type="button" onClick={ this.removeWork } data="skipclick" aria-label="Remove">
          <Trash fill="#fff" />
        </button>
      </>
    );

    return (
      <WorkPictureImage
        zoomPreviewUrl={ frameOption.zoomPreviewUrl }
        title={ title }
        dragging={ dragging }
        dpi={ this.props.dpi }
        previewHeight={ frameOption.previewHeight }
        previewWidth={ frameOption.previewWidth }
      >
        { controls }
      </WorkPictureImage>
    );
  }
}
