import React, { PureComponent } from 'react';
import T from 'prop-types';

import Classes from './styles';

const ESCAPE = 27;

class Backdrop extends PureComponent {
  static propTypes = {
    children: T.node.isRequired,
    onCancel: T.func
  }

  constructor() {
    super();

    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.setRootRef = this.setRootRef.bind(this);
    this.fadeIn = this.fadeIn.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.onNextRepaint(this.fadeIn);
    document.addEventListener('keydown', this.onKeyPressed, { capture: true });
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.raf);
    document.removeEventListener('keydown', this.onKeyPressed, { capture: true });
  }

  onNextRepaint(callback) {
    this.raf = window.requestAnimationFrame(() => {
      this.raf = window.requestAnimationFrame(() => {
        callback();
      });
    });
  }

  onKeyPressed(e) {
    const { onCancel } = this.props;
    if (e.keyCode === ESCAPE && onCancel) {
      onCancel();
    }
  }

  onCancel(e) {
    const { onCancel } = this.props;
    if (onCancel && e.target === this.el) {
      onCancel();
    }
  }

  setRootRef(el) {
    this.el = el;
  }

  fadeIn() {
    this.el.classList.add(Classes.active);
  }

  render() {
    const { children } = this.props;
    return (
      <div
        className={ Classes.backdrop }
        onClick={ this.onCancel }
        ref={ this.setRootRef }
      >
        { children }
      </div>
    );
  }
}

export default Backdrop;
