import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import classes from './classes';
import { scaledPreviewDmentions } from '../../../shared/preview_const';

const WorkPictureImage = ({
  previewHeight, previewWidth, dpi, zoomPreviewUrl, dragging, title, children
}) => {
  const style = scaledPreviewDmentions(previewWidth, previewHeight, dpi);

  return (
    <div
      style={ style }
      className={ classnames(classes.work, { [classes.dragging]: dragging }) }
    >
      <img src={ zoomPreviewUrl } alt={ title } style={ style } draggable="false" />
      { children }
    </div>
  );
};

WorkPictureImage.propTypes = {
  previewHeight: PropTypes.number.isRequired,
  previewWidth: PropTypes.number.isRequired,
  dpi: PropTypes.number.isRequired,
  zoomPreviewUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dragging: PropTypes.bool,
  children: PropTypes.node
};

export default WorkPictureImage;
